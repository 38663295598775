























import { Component, Vue } from 'vue-property-decorator'
import OpenIssues from '@/main/issues/components/dashboard/OpenIssues.vue'
import FirstSteps from '@/main/dashboard/components/FirstSteps.vue'
@Component({
  components: { FirstSteps, OpenIssues }
})
export default class Dashboard extends Vue {}
