
















































































import { Component, Vue } from 'vue-property-decorator'
import { Product } from '@/models/Product.model'

@Component
export default class FirstSteps extends Vue {
  private steps = 1

  get currentProduct(): Product {
    return this.$store.state.product.currentProduct
  }
}
