











import { Component, Vue } from 'vue-property-decorator'
import { IssueService } from '@/main/issues/services/IssueService'

@Component
export default class OpenIssues extends Vue {
  private countIssues: number | null = null

  async created() {
    this.countIssues = await IssueService.countIssuesForCurrentProduct()
  }
}
